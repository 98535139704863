import React, { useEffect } from 'react';

function BackgroundImageComponent({ data }) {
    useEffect(() => {
      const resizeImages = () => {
        const images = document.querySelectorAll('.imageslide');
        images.forEach(img => {
          // Set height to be one third of the width
          img.style.height = `${img.offsetWidth / 3}px`;
        });
      };
  
      resizeImages(); // Call this function on mount
      window.addEventListener('resize', resizeImages);
      return () => window.removeEventListener('resize', resizeImages);
    }, []);
  
    return (
      <div className="w-full bg-cover rounded-md overflow-hidden">
        {data.backgroundCompany && typeof data.backgroundCompany === 'string' &&
          <img src={data.backgroundCompany} alt="Background" className="imageslide w-full" style={{ height: 'auto' }} />
        }
      </div>
    );
  }
  

export default BackgroundImageComponent;
