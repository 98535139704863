import React, { useState } from 'react';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';

function Stars({ id, name, onRatingChange, isRated, isSubmitClicked, isParent }) {
  const [value, setValue] = useState(3);
  const [hover, setHover] = useState(-1);

  const containerStyle = {
    borderRight: isParent ? '0px solid red' : 'none',
    borderLeft: isParent ? '0px solid red' : 'none',
  };

  const labels = {
    0: '',
   
    1: 'Useless',
   
    2: 'Poor',
   
    3: 'Ok',
   
    4: 'Good',
   
    5: 'Excellent',
  };

  const smileys = {
    0: '',
   
    1: '😞',
   
    2: '😕',
   
    3: '🙂',
   
    4: '😀',
   
    5: '😍',
  };

  function getLabelText(value) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onRatingChange(newValue);
  };

  const isNotRatedAndSubmitClicked = !isRated && isSubmitClicked;
  const starClassName = isNotRatedAndSubmitClicked ? 'border-red-500' : '';
  const ratingLabel = labels[value];
  const smiley = smileys[value];

  return (
    <div style={containerStyle} className={`bg-white rounded-lg my-5 py-2 border-2 ${starClassName}`}>
      <div className='flex justify-center'>
        <h3 className='text-gray-400 mb-2'>{name}</h3>
      </div>
      <div className='flex justify-center'>
        <Box
          sx={{
            width: 400,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Rating
            name="hover-feedback"
            value={value}
            precision={1}
            getLabelText={getLabelText}
            onChange={(event, newValue) => {
              handleChange(event, newValue);
            }}
            onChangeActive={(event, newHover) => {
              setHover(newHover);
            }}
            emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
          />
        </Box>
      </div>
      <div className='flex justify-center'>
        <p className='text-gray-400 text-3xl'>{smiley}</p>
      </div>
      <div className='flex justify-center'>
        <p className='text-gray-400'>{ratingLabel}</p>
      </div>
    </div>
  );
}

export default Stars;
