import React, { useEffect, useState } from 'react';
import Stars from '../Components/Stars';
import { useLocation } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import BackgroundImageComponent from '../Components/BackgroundImageComponent';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Home() {
  const query = useQuery();
  const restaurant = query.get('business');
  useEffect(() => {
    // Function to parse query parameters
    const area = query.get('area');     // area if exist
    const floor = query.get('floor');    // area if existfloor
  
    // Check if both 'area' and 'floor' are present
    const hasAreaAndFloor = area !== null && floor !== null;
console.log(hasAreaAndFloor,'hasAreaAndFloor')
console.log(area,'area')

console.log(floor,'floor')

    // Save flags and values to localStorage
    localStorage.setItem('hasAreaAndFloor', hasAreaAndFloor);
    if (hasAreaAndFloor) {
      localStorage.setItem('area', area);
      localStorage.setItem('floor', floor);
    }
  }, [query]);


  const [data, setData] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [errors, setErrors] = useState({ fullName: '', phoneNumber: '' });
  const [selectedItems, setSelectedItems] = useState([]);
  const [items, setItems] = useState([]);
  const [note, setNote] = useState('');
  const [serviceRatings, setServiceRatings] = useState([]);
  const [additionRatings, setAdditionRatings] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("ar");


  useEffect(() => {
    const storedLanguage = localStorage.getItem('lann');
    if (storedLanguage !== 'ar' && storedLanguage !== 'en') {
      localStorage.setItem('lann', 'ar');
      setSelectedLanguage('ar'); // Set selected language state if not 'ar' or 'en'
    } else {
      setSelectedLanguage(storedLanguage); // Set selected language state based on local storage
      localStorage.setItem('lann', storedLanguage);
    }
  }, []);
  const changeLanguagee = () => {
    const newLang = selectedLanguage === "en" ? "ar" : "en";
    localStorage.setItem('lann', newLang);
    setSelectedLanguage(newLang);
    console.log(`Language changed to: ${newLang}`);
  };


  useEffect(() => {
    setIsActive(true);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => setIsActive(false), 5000);
    return () => clearTimeout(timer);
  }, []);
  const [theParent,setTheParent]=useState('....')

  useEffect(() => {
    setIsLoadingData(true);

    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.super-rate.x-tag.tech/api/RestaurantByUuid/${restaurant}`);
        console.log(response)
        const services = response.data.services || [];

       
        const parentObject = services.find(item => item?.parent === true);
        const otherServices = services.filter(item => item !== parentObject);
        
        let orderedServices;
        if (parentObject) {

          orderedServices = [...otherServices, parentObject]; // Place parent object last
        } else {
          orderedServices = [...otherServices]; // Only otherServices if no parentObject
        }
        
        setData({ ...response.data, services: orderedServices });
        
        setTheParent(parentObject?.name) 
        setItems(response.data.additions || []);
        setIsLoadingData(false);
      } catch (error) {
        setError('Failed... please  come back later');
        console.error('Failed to fetch data:', error);
      }
    };
    fetchData();
    setIsVisible(true);
    const timer = setTimeout(() => setIsVisible(false), 5000);
    return () => clearTimeout(timer);
  }, [restaurant]);

  const handleInputChange = (field, value) => {
    const newErrors = { ...errors };
    if (field === 'fullName') {
      setFullName(value);
      newErrors.fullName = value.trim() ? '' : 'Full name is required.';
    } else if (field === 'phoneNumber') {
      setPhoneNumber(value);
      newErrors.phoneNumber = /^\d+$/.test(value) ? '' : 'Invalid phone number.';
    }
    setErrors(newErrors);
  };

  const handleSelectItem = (itemName) => {
    const itemToAdd = data.additions.find(item => item.name === itemName);
    if (itemToAdd) {
      setSelectedItems(prevItems => [...prevItems, itemToAdd]);
      setItems(prevItems => prevItems.filter(it => it.name !== itemName));
    }
  };

  const handleRatingChange = (id, value, type) => {
    const newRating = { id, value: Number(value) };
    if (type === 'service') {
      setServiceRatings(prevRatings => (
        [...prevRatings.filter(rating => rating.id !== id), newRating]
      ));
    } else if (type === 'addition') {
      setAdditionRatings(prevRatings => (
        [...prevRatings.filter(rating => rating.id !== id), newRating]
      ));
    }
  };

  const handleSubmit = async () => {
    setIsSubmitClicked(true);

    if (data.infoStatus && (!fullName.trim() || !phoneNumber.trim())) {
      setError('Please fill all data...');
      setErrors({
        fullName: !fullName.trim() ? 'Full name is required.' : '',
        phoneNumber: !phoneNumber.trim() ? 'Phone number is required.' : '',
      });
      return;
    }
  
    if (data?.services && serviceRatings.length !== data.services.length) {
      setError('Please rate all services.');
      return;
    }
  
    setIsSubmitting(true);
    setError('');
  
    const payload = new FormData();
    payload.append('name', fullName);
    payload.append('phone', phoneNumber);
    payload.append('restaurant_uuid', restaurant);
    payload.append('note', note);
  
    serviceRatings.forEach((service, index) => {
      payload.append(`services[${index}][id]`, service.id);
      payload.append(`services[${index}][rating]`, service.value);
    });
  
    additionRatings.forEach((addition, index) => {
      payload.append(`additions[${index}][id]`, addition.id);
      payload.append(`additions[${index}][rating]`, addition.value);
    });
  
    try {
      const response = await axios.post('https://api.super-rate.x-tag.tech/api/storeRating', payload);
      console.log('asd')
      setIsActive(true)
      setIsSubmitted(true);
      if(localStorage.getItem('hasAreaAndFloor')=='true'){
      console.log('true')
      window.location.href = `https://user.artista-menu.com/?floor=${localStorage.getItem('floor')}&area=${localStorage.getItem('area')}`;
    }
      else{
        console.log('false')

        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
      
    } catch (error) {
      setError('Failed to submit rating.');
      console.error('Failed to submit rating:', error);
    }
    setIsSubmitting(false);
  };
  useEffect(() => {
    const resizeImages = () => {
        const images = document.querySelectorAll('.imageslide');
        images.forEach(img => {
            // Set height to be one third of the width
            img.style.height = `${img.offsetWidth / 2}px`;
        });
    };

    resizeImages(); // Call this function on mount

    // Add a resize event listener to adjust the height on window resize
    window.addEventListener('resize', resizeImages);

    // Clean up the event listener on component unmount
    return () => {
        window.removeEventListener('resize', resizeImages);
    };
}, []); // Empty dependency array means this effect runs only on mount and unmount

  if (isLoadingData) {
    return <div className="flex justify-center items-center h-screen"><CircularProgress /></div>;
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4 ">





<div className=' flex justify-end w-screen mr-10 mb-2'>
    <div
    style={{
     
      color: "black",
      padding: "0.4rem 0.3rem",
      borderRadius: "5px",
      textTransform: "capitalize",
      fontWeight: "bold",
      outline: "none",
      border: "1px solid #000",
      cursor: "pointer",
      fontSize: "13px",
      alignItems: "center",
      width: "80px", // Adjusted width to fit 'English' and 'Arabic'
      display: "flex",
      justifyContent: "center",
      position: "relative",
      display:'flex',
      justifyItems:'center',
      zIndex:'100',
    }}
    onClick={changeLanguagee}
  >
  <h2 style={{
      margin: 0, // Remove margin to ensure true centering
      alignSelf: "center" // Added to vertically center the text within the flex container
    }}>
      {selectedLanguage === "en" ? "English" : "عربي"} {/* Changed to full names */}
    </h2>
  </div>

  </div>






   {/** */} <div className='fixed top-0 bottom-0 right-0 left-0 z-10 bg-cover bg-gradient-to-br from-[#fff] to-[#fff]'></div>
      <div className="flex flex-col items-center w-full p-6 bg-white bg-opacity-30 backdrop-blur-sm rounded-lg shadow-2xl z-20">
      <div className="flex flex-col items-center justify-center w-full mb-10 bg-opacity-30 backdrop-blur-sm rounded-lg shadow-xl z-20">

<BackgroundImageComponent data={data}/>
      </div>

    {data.logoCompany && typeof data.logoCompany === 'string' && (
      <img className="w-24 h-24 rounded-full mb-8 " src={data.logoCompany} alt="Restaurant Logo" />
    )} 

        <div className="space-y-4 w-full md:w-3/4">
          {data.infoStatus && (
            <div className="space-y-4 w-full">
              <TextField
                id="full-name"
                label={selectedLanguage=='ar'?'الاسم الكامل':'Full Name'}
                variant="outlined"
                value={fullName}
                onChange={(e) => handleInputChange('fullName', e.target.value)}
                error={!!errors.fullName}
                helperText={errors.fullName}
                fullWidth
              />
              <TextField
                id="phone-number"
                label={selectedLanguage=='ar'?'رقم الهاتف':'Phone Number'}
                variant="outlined"
                value={phoneNumber}
                onChange={(e) => handleInputChange('phoneNumber', e.target.value)}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber}
                fullWidth
              />
            </div>
          )}
          {data?.services && data.services.map(service => (
            <Stars
              key={service?.idReal}
              id={service?.idReal}
              name={service?.name}
              onRatingChange={(value) => handleRatingChange(service?.idReal, value, 'service')}
              isRated={serviceRatings.some(rating => rating.id === service?.idReal)}
              isParent={service?.parent === true} // New prop to indicate parent status
              isSubmitClicked={isSubmitClicked}
            />
          ))}
          {data?.additions && data.additions.length !== 0 && data.additionalStatus==true && theParent!=='....' && theParent!==undefined &&
            <div className="relative w-full">
            <div className="mb-2">
            {selectedLanguage=='en'?
              <h2> help us know more about how our services was by adding more reviews (optional)</h2>
              :
              <div >
              <h2 className=' text-right'>  ساعدنا في تحسين خدماتنا ومنتجاتنا  من خلال مشاركتنا تقييمك بتفاصيل أكثر          </h2>
              <h2 className=' text-right'>  (إختياري)          </h2>
              </div>
            }
            </div>
            <select
                className="w-full p-3 bg-white bg-opacity-50 border border-gray-300 rounded-lg focus:outline-none z-10 relative"
                onChange={(e) => handleSelectItem(e.target.value)}
                value=""
            >
            <option value="" disabled style={{ textAlign: selectedLanguage === 'ar' ? 'right' : 'left' }}>
            {selectedLanguage === 'ar' ? `تقييمات اضافية عن ${theParent}` : `Additional ratings for ${theParent}`}
          </option>
                {items?.map((item) => (
                    <option key={item?.idReal} value={item?.name}>
                        {item?.name}
                    </option>
                ))}
            </select>
        </div>
        
        }
          {selectedItems.map(item => (
            <Stars key={item.id} id={item.id} name={item.name} onRatingChange={(value) => handleRatingChange(item.idReal, value, 'addition')} />
          ))}
          <TextField
          label={selectedLanguage === 'ar' ? 'ملاحظات' : 'Notes'}
          multiline
          rows={2}  // Specify the number of rows for the textarea
          fullWidth
          value={note}
          onChange={(e) => setNote(e.target.value)}
          variant="outlined"
        />
        </div>

        {isVisible && data.startMessageCompany && data.messageStatus && (
          <div className={`start-message fixed inset-0 z-50 h-screen flex flex-col items-center justify-center bg-black bg-opacity-50 ${isActive ? 'active' : ''}`}>
            <div className={`message-container bg-white rounded-lg shadow-lg overflow-hidden ${isActive ? 'active' : ''}`}>
              
              <div className="message-content p-4 text-center">
                {data.startMessageCompany}
              </div>
            </div>
          </div>
        )}

        {isSubmitted && data.endMessageCompany && data.messageStatus && (
          <div className={`end-message fixed inset-0 z-50 flex flex-col items-center justify-center bg-black bg-opacity-50 ${isActive ? 'active' : ''}`}>
            <div className={`message-container bg-white rounded-lg shadow-lg overflow-hidden ${isActive ? 'active' : ''}`}>
            
              <div className="message-content p-4 text-center">
                {data.endMessageCompany}
              </div>
            </div>
          </div>
        )}
        {error && <div className="text-red-500">{error}</div>}
        <button
          className="mt-4 py-2 px-5 text-sm font-medium text-gray-900 border border-gray-200 rounded-lg shadow-sm backdrop-blur-md bg-white/40 focus:outline-none focus:ring-2 ring-indigo-300"
          onClick={() => handleSubmit()}
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress size={24} /> : `${selectedLanguage=='ar'?'تاكيد':'Submit'}`}
        </button>
      </div>
    </div>
  );
}

export default Home;
